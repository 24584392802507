import React, { Component } from "react";
import { Row, Col, Container, Card } from "bootstrap-4-react";
import { IconButton, Button } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import Meta from "../../meta/Meta";
import "../../style/ArticleMain.scss";
import ArticleImageLoader from "./ArticleImageLoader";
import { Spinner } from "react-bootstrap";

class ArticleMain extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per: 4,
      articleData: [],
      categoryID: null,
      noData: "",
      selectedTab: 0,
      loading: true,
    };
    this.articleListData = this.articleListData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    this.articleListData();
    this.scrollListener = window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  articleListData = () => {
    const { per, page, articleData } = this.state;
    this.setState({ loading: true });
    const isBlog = this.props.category_id === 6;
    const endpoint = isBlog
      ? "blog/get_all_blog"
      : "article/get_all_article";
    const body = {
      id: "",
      category_id: isBlog ? undefined : this.props.category_id,
      user_id: getID(),
    };
    axios
      .post(apiURL(`${endpoint}?per_page=${per}&page=${page}`), body, {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            if (resultData.result.length > 0) {
              const updatedEventsList = [...articleData, ...resultData.result];
              this.setState({
                articleData: updatedEventsList,
                scrolling: true,
              });
            } else {
              this.setState({ noData: "", scrolling: false });
            }
          } else {
            this.setState({ noData: "", scrolling: false });
          }
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: false,
        }),
        this.articleListData
      );
    }
  };

  handleScroll = () => {
    if (this._isMounted) {
      const lastLi = document.querySelector(".mainSection");
      if (lastLi !== null) {
        const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        const pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset && this.state.scrolling) {
          this.loadMore();
        }
      }
    }
  };

  redirectArticle = (e, id, title, category) => {
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      if (this.props.category_id === 6) {
        this.props.history.push(
          "/blog/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
          { category }
        );
      } else {
        this.props.history.push(
          "/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
          { category }
        );
      }
      this.addView(id);
    }
  };

  addView = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      article_id: this.props.category_id === 6 ? "" : id,
      blog_id: this.props.category_id === 6 ? id : "",
      user_id: getID(),
    };
    axios
      .post(apiURL("views/add_views"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.props.history.replace("/sign");
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: this.props.category_id === 6 ? "blogs" : "articles",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.articleData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.articleData];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                articleData: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: this.props.category_id === "6" ? "blogs" : "articles",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.articleData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            articleData: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { language } = this.props;
    const filteredData = language
      ? this.state.articleData.filter(
          (event) => event.fldv_language === language
        )
      : this.state.articleData;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

        <Row className="articleMain">
          {filteredData.map((arrArticle, index) => {
            return (
              <Col
                key={index}
                col="12 col-lg-6 col-xl-4"
                className="mx-auto custom-col-spacing mt-4"
              >
                <Card
                  style={{ cursor: "pointer" }}
                  onClick={(e) =>
                    this.redirectArticle(
                      e,
                      arrArticle.fldi_id,
                      arrArticle.fldv_title,
                      this.props.category_name
                    )
                  }
                >
                  <Card.Body>
                    <figure>
                      <div className="d-block mainImage">
                        <ArticleImageLoader
                          imageUrl={arrArticle.fldv_image_url}
                          altText={arrArticle.fldv_title}
                        />
                      </div>
                    </figure>
                    <div className="content">
                      <div className="topic">
                        <h4>{arrArticle.fldv_title}</h4>
                      </div>
                      <div className="date">
                        <h4>{arrArticle.fldv_author_name}</h4>
                      </div>
                    </div>
                  </Card.Body>

                  <div className="actionArtMain">
                    <Button
                      variant="contained"
                      size="small"
                      className="readmore"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.redirectArticle(
                          e,
                          arrArticle.fldi_id,
                          arrArticle.fldv_title
                        );
                      }}
                    >
                      Read More
                    </Button>
                    {arrArticle.is_bookmark ? (
                      <IconButton
                        style={{ color: "orange" }}
                        className="bookmark"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleRemoveBookMark(e, arrArticle.fldi_id);
                        }}
                      >
                        <Bookmark />
                      </IconButton>
                    ) : (
                      <IconButton
                        className="bookmark"
                        onClick={(e) => {
                          e.stopPropagation();
                          this.handleAddBookMark(e, arrArticle.fldi_id);
                        }}
                      >
                        <BookmarkBorder />
                      </IconButton>
                    )}
                  </div>
                </Card>
              </Col>
            );
          })}

          <Col sm="12" className="mt-4 text-center">
            <h1>{this.state.noData}</h1>
          </Col>
        </Row>
        <div
          ref={(loadingRef) => (this.loadingRef = loadingRef)}
          style={{ height: "30px", margin: "10px" }}
        >
          <span
            style={{
              display: this.state.loading ? "block" : "none",
              textAlign: "center",
            }}
          >
            <Spinner variant="primary" />
          </span>
          <span>{this.state.scrolling ? this.state.noData : ""}</span>
        </div>
      </React.Fragment>
    );
  }
}

export default ArticleMain;
