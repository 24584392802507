import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Appbar from "../Appbar";
import Footer from "../Footer";
import "../../style/Achievements.scss";
import axios from "axios";
import { getContact, apiURL, getID } from "../../utils";
import PastWebinarAchieve from "./PastWebinarAchieve";
import PastTrainAchieve from "./PastTrainAchieve";
const Achievements = () => {
  const [webinar, setWebinar] = useState([]);
  const [training, setTraining] = useState([]);
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",

    schcollname: "",
  });
  useEffect(() => {
    getAllData();
    fetchUserProfile();
  }, []);
  const getAllData = () => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      contact: getContact(),
    };
    axios
      .post(apiURL("achievement/getAchievement"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.success === true || resultData.success === 1) {
          setTraining(resultData.result.training);
          setWebinar(resultData.result.webinar);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchUserProfile = async () => {
    const body = {
      fldi_id: getID(),
    };
    const header = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    try {
      const response = await axios.post(apiURL("teacher/get_profile"), body, {
        headers: header,
      });
      const result = response.data.result;

      const firstname =
        result.fldv_first_name !== ""
          ? result.fldv_first_name
          : result.fldv_name;
      const lastname =
        result.fldv_last_name !== ""
          ? result.fldv_last_name
          : result.fldv_surname;

      const schcollname = result.fldv_school_college;

      const userProfile = {
        firstname: firstname,
        lastname: lastname,
        schcollname: schcollname,
      };
      setUser(userProfile);
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  };
  return (
    <Fragment>
      <Helmet>
        <title>Achievement</title>

        <meta charset="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="Achievement" />
        <link rel="canonical" href={window.location.href} />

        <meta name="description" content="Achievement" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Appbar />
      <h2 className="text-center d-block w-100 heading">My Achievements</h2>
      <PastTrainAchieve pastTrain={training} user={user} />
      <PastWebinarAchieve pastWeb={webinar} user={user} />
      <div className="customFooter mt-0">
        <Footer />
      </div>
    </Fragment>
  );
};

export default Achievements;
