import React, { Component, forwardRef } from "react";
import { Navbar, Nav, Dropdown, Collapse } from "bootstrap-4-react";
import "../style/Navmenu.scss";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../utils";
import { setMenus as initialMenus } from "../common/navbarData";

class Navmenu extends Component {
  _isMounted = false;
  previousMenus = []; // To store the previous state of menus

  constructor(props) {
    super(props);
    const storedMenus = localStorage.getItem("menuData");
    this.state = {
      showTexts: false,
      setMenus: storedMenus ? JSON.parse(storedMenus) : initialMenus,
    };
    this.previousMenus = this.state.setMenus; // Initialize previousMenus
  }

  showText = () => {
    if (window.scrollY <= 700) {
      this.setState({ showTexts: true });
    } else {
      this.setState({ showTexts: false });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.showText);
    this.loadMenusFromLocalStorage();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.showText);
  }

  loadMenusFromLocalStorage = () => {
    const storedMenus = localStorage.getItem("menuData");
    if (storedMenus) {
      this.setState({ setMenus: JSON.parse(storedMenus) });
    } else {
      localStorage.setItem("menuData", JSON.stringify(initialMenus));
    }
  };

  getMenus = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("menu/get_all_menu_test"), { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const newMenus = resultData.result;
            // Compare previous and current menus
            if (JSON.stringify(this.previousMenus) !== JSON.stringify(newMenus)) {
              // Update state and local storage if there is a change
              this.setState({ setMenus: newMenus }, () => {
                localStorage.setItem("menuData", JSON.stringify(newMenus));
              });
              // Update previousMenus to the new state
              this.previousMenus = newMenus;
            }
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching menus:", error);
      });
  };

  render() {
    const { setMenus } = this.state;
    return (
      <React.Fragment>
        <Navbar
          key={JSON.stringify(setMenus)} // Force re-render by changing key
          className={
            this.state.showTexts
              ? "navbar-light NavBox showText"
              : "sticky-top navbar-light NavBox"
          }
          expand="lg"
        >
          <Navbar.Toggler target="#navbarSupportedContent" />
          <Collapse navbar id="navbarSupportedContent">
            <Navbar.Nav mx="auto" className="NavbarBox">
              {setMenus.map((arrMenu, index) => (
                <span key={index}>
                  {arrMenu.arrChilds.length > 0 ? (
                    <Nav.Item dropdown>
                      <Nav.Link className="nav-link linkBox" dropdownToggle>
                        {arrMenu.fldv_name}
                      </Nav.Link>
                      <Dropdown.Menu>
                        {arrMenu.arrChilds.map((arrChildMenu, childIndex) => (
                          <span key={childIndex}>
                            <NavLink
                              activeClassName="active_class"
                              exact
                              className="nav-link"
                              to={arrChildMenu.fldv_to_path}
                            >
                              {arrChildMenu.fldv_name}
                            </NavLink>
                            <Dropdown.Divider />
                          </span>
                        ))}
                      </Dropdown.Menu>
                    </Nav.Item>
                  ) : (
                    <Nav.Item>
                      <NavLink
                        activeClassName="active_class"
                        exact
                        className="nav-link linkBox"
                        to={arrMenu.fldv_to_path}
                      >
                        {arrMenu.fldv_name}
                      </NavLink>
                    </Nav.Item>
                  )}
                </span>
              ))}
            </Navbar.Nav>
          </Collapse>
        </Navbar>
      </React.Fragment>
    );
  }
}

// Forward the ref to the Navmenu component
export default forwardRef((props, ref) => <Navmenu {...props} ref={ref} />);