import React from "react";
import { useImage } from "react-image";

const fallbackImage =  require("../images/Navdisha-Logo-PNG.png");

const TrainingDefaultImageLoader = ({ imageUrl, altText,className = ""  }) => {
  const { src } = useImage({
    srcList: [imageUrl, fallbackImage], // Try imageUrl first, then fallbackImage
    useSuspense: false,
  });
  
  return <img src={src} alt={altText} className={className?className:"img-fluid" } width={"450px"} height={"280px"}/>;
};

export default TrainingDefaultImageLoader;
