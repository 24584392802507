import React from "react";
import SanitizedHTML from "react-sanitized-html";
import moment from "moment";

const date = new Date();

export const basePath = () => {
  return window.location.origin.toString() + "/";
};

export const login = (userData) => {
  localStorage.setItem("login_status", true);
  localStorage.setItem("user_detail", JSON.stringify(userData));
};

export const logout = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("isLoggedin");
};

export const isLogin = () => {
  if (window.localStorage.getItem("login_status")) {
    return true;
  } else {
    return false;
  }
};

export const getID = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.fldi_id;
  } else {
    return false;
  }
};

export const getName = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if (userID.fldv_first_name === "") {
      return userID.fldv_name ? userID.fldv_name : "";
    } else {
      return userID.fldv_first_name ? userID.fldv_first_name : "";
    }
  } else {
    return "";
  }
};
export const getLastName = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if (userID.fldv_last_name === "" || userID.fldv_last_name === null) {
      return userID.fldv_surname ? userID.fldv_surname : "";
    } else {
      return userID.fldv_last_name ? userID.fldv_last_name : "";
    }
  } else {
    return "";
  }
};
export const getUsername = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if (userID.fldv_first_name === "") {
      return userID.fldv_username;
    } else {
      return userID.fldv_first_name;
    }
  } else {
    return false;
  }
};
export const getRole = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.fldv_faculty_status;
  } else {
    return false;
  }
};

export const getEmail = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.fldv_email;
  } else {
    return "";
  }
};

export const getContact = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.fldv_contact;
  } else {
    return "";
  }
};

export const getCity = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID?.fldi_city || "";
  } else {
    return false;
  }
};
export const getPincode = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.fldv_pincode;
  } else {
    return false;
  }
};
export const getBoard = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if (userID.fldv_board_medium === "" || userID.fldv_board_medium === null) {
      return userID.fldv_board + "-" + userID.fldv_medium;
    } else {
      return userID.fldv_board_medium;
    }
  } else {
    return false;
  }
};
export const getGrades = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if (
      userID.fldv_grades_taught === "" ||
      userID.fldv_grades_taught === null
    ) {
      return userID.fldv_faculty;
    }
    return userID.fldv_grades_taught;
  } else {
    return false;
  }
};
export const getSubjects = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if (
      userID.fldv_subjects_taught === "" ||
      userID.fldv_subjects_taught === null
    ) {
      const sub = userID.fldv_subject.split(" ");
      if (sub.includes(",")) {
        return sub.join("");
      } else {
        return sub.join(",");
      }
    } else {
      return userID.fldv_subjects_taught;
    }
  } else {
    return false;
  }
};
export const getPin = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.fldv_login_pin;
  } else {
    return false;
  }
};

export const getSchCollName = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    if(userID?.schoolCollege){
      return userID.schoolCollege;
    }
    return userID?.fldv_school_college;
  } else {
    return false;
  }
};

export const passwordStatus = () => {
  if (window.localStorage.getItem("login_status")) {
    const userID = JSON.parse(localStorage.getItem("user_detail"));
    return userID.flg_password_status;
  } else {
    return false;
  }
};

//  TODO : this is poining to OLD api's
// export const apiURL = (method) => {
//   //return "https://beelive.in/navneet/api/"+method;
//   // return "https://www.navdisha.co.in/navneet/api/"+method;
//   // return "https://navdisha.navneet.com/navneet/api/" + method;
//   return "https://navdishastaging.navneet.com/navneet/api/" + method;
// };

//  TODO : this is poining to new api's
export const apiURL = (method) => {
  // return "https://navdishastagingapi.navneet.com/" + method; //staging-api
  return "https://navdishaapi.navneet.com/" + method; //prod-api
};
export const getCurrentDate = () => {
  return date.getDate();
};

export const getCurrentMonth = () => {
  let currentMonth = date.getMonth() + 1;
  return currentMonth < 10 ? 0 + "" + currentMonth : currentMonth;
};
export const getCurrentYear = () => {
  return date.getFullYear();
};

export const getCurrentTime = () => {
  return date.toLocaleString();
};

export const sanitizeHtml = (str) => {
  return <SanitizedHTML html={str} />;
};

export const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};
