import React, { Component, createRef } from "react";
import "../../style/BookMark.scss";
import { Row, Col, Card } from "bootstrap-4-react";

import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../../utils";
import axios from "axios";
import Moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";
import {
  Snackbar,
  IconButton,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import Meta from "../../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
// import logoNew from "../images/navdisha.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RegistrationForm from "../../webinar/RegistrationForm";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import ArticleImageLoader from "../Article/ArticleImageLoader";
const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
});

class UpcomingEveBookmark extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      registerDialog: false,
      id: "",
      title: "",
      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,
      selectedItem: 0,
      upcomingEvents: this.props.upcomingEvents,
      isMobile: false,
    };

    this.carouselRef = createRef();
  }

  handleClickOpen = (e, id, title) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ open: true, id: id, title: title });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
    this.getUserData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.registerDialog !== this.state.registerDialog &&
      this.state.registerDialog
    ) {
      this.getUserData();
    }
    if (prevProps.upcomingEvents !== this.props.upcomingEvents) {
      this.setState({ upcomingEvents: this.props.upcomingEvents });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleReadMore = (e, id, title) => {
    e.preventDefault();

    this.props.history.push("/webinar/" + id + "/" + title);
  };

  getUserData = () => {
    const body = {
      fldi_id: getID(),
    };
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher/get_profile"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const firstname =
            resultData.result.fldv_first_name !== ""
              ? resultData.result.fldv_first_name
              : resultData.result.fldv_name;
          const lastname =
            resultData.result.fldv_last_name !== ""
              ? resultData.result.fldv_last_name
              : resultData.result.fldv_surname;
          const role = resultData.result.fldv_faculty_status;
          const contact = resultData.result.fldv_contact;
          const email = resultData.result.fldv_email;
          const city = resultData.result.fldi_city;
          const schcollname = resultData.result.fldv_school_college;
          const pincode = resultData.result.fldv_pincode;
          const board = resultData.result.fldv_board_medium;

          const grades = resultData.result.fldv_grades_taught
            ? resultData.result.fldv_grades_taught
                .split(",")
                .map((grade) => grade.trim())
            : [];

          const subjects = resultData.result.fldv_subjects_taught
            ? resultData.result.fldv_subjects_taught
                .split(",")
                .map((sub) => sub.trim())
            : [];
          const userData = {
            firstname,
            lastname,
            role,
            city,
            contact,
            email,
            schcollname,
            pincode,
            board,
            grades,
            subjects,
          };
          this.setState({
            userData: userData,
          });
        } else {
          this.setState({
            noData: "Coming Soon",
            loading: false,
            scrolling: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleRegistrationSuccess = (isJoined, webinarId) => {
    const updatedEventsList = this.state.upcomingEvents.map((event) => {
      if (event.fldi_id === webinarId) {
        return { ...event, join_webinar: isJoined };
      }
      return event;
    });

    this.setState({ upcomingEvents: updatedEventsList });
  };
  registerForWebinar = (e, webinarId, title, date, time) => {
    e.stopPropagation();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      // this.getUpComeinEvents();
      this.setState({
        registerDialog: true,
        id: webinarId,
        title: title,
        date: date,
        time: time,
      });
    }
  };

  registerWebinarClose = () => {
    this.setState({ registerDialog: false, id: "", title: "" });
    this.getUserData();
  };

  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedItem, per } = this.state;
    const { upcomingEvents, handleRemoveUpWebinarBookmark } = this.props;
    this.props.handleRemoveUpWebinarBookmark(id);
    const updatedEvents = upcomingEvents.filter(
      (event) => event.fldi_id !== id
    );
    const totalPages = Math.ceil(updatedEvents.length / per);

    if (selectedItem >= totalPages && totalPages > 0) {
      this.setState({ selectedItem: totalPages - 1 });
    }
  };

  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.props.upcomingEvents.length / per);

    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };

  render() {
    const { classes, id } = this.props;
    const { selectedItem, per, upcomingEvents, isMobile } = this.state;

    const displayData = isMobile
      ? upcomingEvents.slice(selectedItem, selectedItem + 1)
      : upcomingEvents.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;

    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />

        <div>
          <section className="upcomingEventSection">
            <Container maxWidth="lg" className="container">
              <h2>Upcoming Events</h2>
              <Carousel
                ref={this.carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                className="bookmark-carousel"
                onChange={(index) => this.setState({ selectedItem: index })}
              >
                <Row className="upcomingWebBook position-relative">
                  {displayData.map((arrList) => (
                    <Col
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                      key={arrList.fldi_id}
                    >
                      <Card
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          this.handleReadMore(
                            e,
                            arrList.fldi_id,
                            arrList.fldv_title.replace(/ /g, "-").toLowerCase()
                          )
                        }
                      >
                        <Card.Body>
                          <div className="position-relative">
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <div className="Speaker">
                                  <Button
                                    className="mode"
                                    style={{
                                      background:
                                        arrList.fldv_mode === "Physical"
                                          ? "#064e89"
                                          : "#a03fa0",
                                    }}
                                  >
                                    {arrList.fldv_mode}
                                  </Button>
                                  <figure>
                                    <div className="d-block imgBox">
                                      <ArticleImageLoader
                                        imageUrl={arrList.fldv_image_url}
                                        alt="img"
                                      />
                                    </div>
                                    <p>{arrList.fldv_speaker_name}</p>
                                  </figure>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <table className="table">
                                  <tr>
                                    <th colSpan="2" className="topic">
                                      {arrList.fldv_title}
                                    </th>
                                  </tr>

                                  <tbody>
                                    <tr>
                                      <th>Date:</th>
                                      <td>
                                        {Moment(
                                          arrList.fldv_selected_date
                                        ).format("ddd, D MMM, YYYY")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Time:</th>
                                      <td>
                                        {Moment(arrList.fldt_time).format("LT")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Duration:</th>
                                      <td>{arrList.fldv_duration} hrs</td>
                                    </tr>
                                    <tr>
                                      <th>Venue:</th>
                                      <td>{arrList.fldv_venue_location}</td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div className="action">
                                  {arrList.isRegistered || arrList.join_webinar ? (
                                    <Button className="registered">
                                      Registered{" "}
                                      <span>
                                        <IoMdCheckmarkCircleOutline />
                                      </span>
                                    </Button>
                                  ) : (
                                    <Button
                                      className="readmore"
                                      disabled={Moment(arrList.fldv_selected_date).isBefore(Moment(), 'day')}
                                      onClick={(e) =>
                                        this.registerForWebinar(
                                          e,
                                          arrList.fldi_id,
                                          arrList.fldv_title,
                                          Moment(
                                            arrList.fldv_select_date
                                          ).format("MM DD YYYY"),
                                          Moment(arrList.fldt_time).format("LT")
                                        )
                                      }
                                    >
                                      Register
                                    </Button>
                                  )}
                                  <IconButton
                                    style={{ color: "orange" }}
                                    className="bookmark"
                                    onClick={(e) =>
                                      this.handleRemoveBookMark(
                                        e,
                                        arrList.fldi_id
                                      )
                                    }
                                  >
                                    <Bookmark />
                                  </IconButton>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel>
              <IconButton
                onClick={this.handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={this.handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>

          <Dialog
            onClose={(e) => this.registerWebinarClose(e)}
            aria-labelledby="customized-dialog-title"
            open={this.state.registerDialog}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={(e) => this.registerWebinarClose(e)}
            >
              {this.state.title}
            </DialogTitle>
            <DialogContent>
              <RegistrationForm
                id={this.state.id}
                userData={this.state.userData}
                date={this.state.date}
                time={this.state.time}
                onClose={this.registerWebinarClose}
                onSnackClose={this.snackBarClose}
                componentUpdate={this.getUpComeinEvents}
                onSuccess={(isJoined) =>
                  this.handleRegistrationSuccess(isJoined, this.state.id)
                }
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(UpcomingEveBookmark);
