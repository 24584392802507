import React, { Component } from "react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
// import "../style/pastraining.scss";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import VideocamIcon from "@material-ui/icons/Videocam";
import { Link } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import axios from "axios";
import Moment from "moment";
import { IconButton, Grid } from "@material-ui/core";
import FeedBackForm from "./FeedBackForm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Sign from "../login/Sign";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { withStyles } from "@material-ui/core/styles";

import "../style/Glimpses.scss";
import Meta from "../meta/Meta";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";

import {
  ArrowDownward,
  ArrowDropDown,
  Translate,
  Bookmark,
  BookmarkBorder,
  DateRange,
} from "@material-ui/icons";
import { Spinner } from "react-bootstrap";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";
const styles = (theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: "10px 30px 10px 10px",
    borderColor: "#064e89",
    borderWidth: "2px",
    borderRadius: "4px",
    appearance: "none",
    backgroundColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#064e89",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#064e89",
  },
  label: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "white",
    padding: "0 5px",
    color: "#064e89",
    fontWeight: "bold",
    transition: "0.2s",
    pointerEvents: "none",
    transformOrigin: "top left",
    "&.float": {
      top: "-10px",
      transform: "scale(0.75)",
    },
  },

  filter_group_year: {
    marginRight: "1rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem",
      marginRight: "2rem",
      fontSize: "0.8rem",
    },
  },
  dialogPaper: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    // borderRadius:'25px',
    // maxWidth:'42.75rem',
    // width:'42.75rem',
    borderRadius: "25px",
    maxWidth: "42.75rem",
  },
  dialogPaper1: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    // borderRadius:'25px',
    // maxWidth:'42.75rem',
    // width:'42.75rem',
    borderRadius: "0px",
    maxWidth: "48.75rem",
    width: "60rem",
  },
  title: {
    margin: theme.spacing(4, 0, 0),
    fontSize: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  title1: {
    // margin: theme.spacing(0, 0, 0),
    fontSize: "1.25rem",
    fontFamily: "gotham rounded medium",
    width: "17rem",
  },
});

class PastTraining extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pastEventsList: [],
      videoUrl: "",
      noData: "",
      showComponent: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
      feedBack: false,
      dialogOpen: false,
      videoUrl: "",
      selectedYear: "",
      uniqueYears: [],
      selectedLan: "",
      showShareDialog: false,
      clickedCardData: null,
      page: 1,
      per: 4,
      loading: false,
      bookmarkStatusList: [],
    };
    this.getPastEvents = this.getPastEvents.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.scroll(0, 0);
    this.getPastEvents();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.pastEventsList !== this.state.pastEventsList) {
      this.extractUniqueYears();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  getPastEvents = () => {
    this.setState({ loading: true });
    const { per, page, pastEventsList } = this.state;
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "PE",
      event_mode: "Physical",
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/events?per_page=" + per + "&page=" + page), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        //alert(resultData.message);
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = [...pastEventsList, ...resultData.result];          
          //const physicalEventsList = updatedEventsList.filter(event=> event.fldv_mode === 'Physical')                    
          this.setState({
            pastEventsList: updatedEventsList,
            scrolling: false,
          });
          this.setState({ loading: false, scrolling: true });
        } else {
          this.setState({ noData: "", loading: false, scrolling: false });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleShareClick = (cardData) => {
    this.setState({ showShareDialog: true, clickedCardData: cardData });
  };

  handleCloseShareDialog = () => {
    this.setState({ showShareDialog: false });
  };
  handlevideoClickOpen = (e, videoUrl) => {
    this.setState({ open: true, videoUrl: videoUrl });
  };
  handlevideoClose = () => {
    this.setState({ open: false, videoUrl: "" });
  };
  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: false,
        }),
        this.getPastEvents
      );
    }
  };
  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector(".pastTrain");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };
  handleClickOpen = (e, id, title) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ feedBack: true, id: id, title: title });
    }
  };
  extractUniqueYears = () => {
    const years = [
      ...new Set(
        this.state.pastEventsList.map((event) =>
          Moment(event.fldv_select_date).format("YYYY")
        )
      ),
    ];

    this.setState({ uniqueYears: years });
  };
  handleAddBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_training",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          //alert(resultData.message);
          if (resultData.success === true || resultData.success === 1) {
            const index = this.state.pastEventsList.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.pastEventsList];
              updatedEventsList[index].is_bookmark = true;
              this.setState({
                pastEventsList: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_training",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = this.state.pastEventsList.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          this.setState({
            pastEventsList: updatedEventsList,
          });
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleYearChange = (e) => {
    const selectedYear = e.target.value;
    this.setState({ selectedYear });
  };
  handleLanguageChange = (e) => {
    const selectedLan = e.target.value;
    this.setState({ selectedLan });
  };
  checkJoinNow = () => {
    this.getPastEvents();
    this.setState({ showComponent: false });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };
  calculateTimeDifference(startTime, endTime) {
    const startMoment = Moment(new Date(startTime));
    const endMoment = Moment(new Date(endTime));
    const differenceInHours = endMoment.diff(startMoment, "hours", true);
    return Math.round(differenceInHours);
  }
  handleReadMore = (e, id, title) => {
    e.preventDefault();
    if (!isLogin()) {
      this.setState({ showComponent: true });
      this.props.history.push("/sign");
    } else {
      this.props.history.push("/training/" + id + "/" + title);
    }
  };
  handleClose = (e) => {
    if (e === true) {
      this.setState({
        showSnackbar: true,
        snackBarMessage:
          "Thank you for getting in touch! We appreciate you contacting us....",
        severityColor: "success",
        snackBarTimer: "3000",
        //open: false
      });
    }
    this.setState({ feedBack: false });
  };

  /* videoDialog = (e, videoUrl) =>{
 // alert(videoUrl);
 this.setState({
 dialogOpen: true,
 videoUrl: videoUrl
 })
 }*/

  videoDialog = (e, videoUrl) => {
    // alert(videoUrl);
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({
        dialogOpen: true,
        videoUrl: videoUrl,
      });
    }
  };

  videoDialogClose = () => {
    //alert("close");
    this.setState({
      dialogOpen: false,
      videoUrl: "",
    });
  };

  render() {
    const { classes } = this.props;
    const {
      selectedYear,
      uniqueYears,

      selectedLan,
    } = this.state;
    const filteredEvents = selectedYear
      ? this.state.pastEventsList.filter(
          (event) =>
            Moment(event.fldv_select_date).format("YYYY") === selectedYear &&
            (selectedLan === "" || event.fldv_language === selectedLan)
        )
      : this.state.pastEventsList.filter(
          (event) => selectedLan === "" || event.fldv_language === selectedLan
        );
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = {
      display: this.state.loading ? "block" : "none",
      textAlign: "center",
    };
    const labelLanClass = `${classes.label} ${selectedLan ? "float" : ""}`;
    const labelYearClass = `${classes.label} ${selectedYear ? "float" : ""}`;
    return (
      <React.Fragment>
        <Meta tableName={"menu"} objectId={8} forPage={"main_page"} />
        {this.state.showComponent ? (
          <Sign
            ref={this._sign}
            pageProp={this.props}
            checkJoinNow={(e) => this.checkJoinNow(e)}
          />
        ) : (
          <div>
            <Appbar />
            <div className="sticky-top sign-light">
              <Navmenu />
            </div>

            <section className="BannerBox TrainingBox">
              <div className="sixteen-nine">
                <div className="content text-center text-white invisible">
                  <h2>Past Training Schedule</h2>
                  <p>PAST TRAINING SCHEDULE TO FEED YOUR BRAIN</p>
                </div>
              </div>
            </section>

            <section class="pastTrain">
              <Container>
                <div className="filter">
                  <div className="yearFilterTrain">
                    <div className="formControlTrain">
                      <label
                        htmlFor="language-select"
                        className={labelYearClass}
                      >
                        <DateRange className="yearSvg" />
                        <span>Year</span>
                      </label>
                      <select
                        id="language-select"
                        value={selectedYear}
                        onChange={this.handleYearChange}
                        className="selectTrain"
                        onFocus={(e) => e.target.classList.add("focused")}
                        onBlur={(e) => e.target.classList.remove("focused")}
                      >
                        <option value="">All</option>
                        {uniqueYears.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                      <ArrowDropDown className={classes.icon} />
                    </div>
                  </div>
                  <div className="languageFilter d-flex justify-content-center">
                    <div className="formControl">
                      <label
                        htmlFor="language-select"
                        className={labelLanClass}
                      >
                        <FaLanguage className="langSvg" />
                        <span>Language</span>
                      </label>
                      <select
                        id="language-select"
                        value={selectedLan}
                        onChange={this.handleLanguageChange}
                        className="select"
                        onFocus={(e) => e.target.classList.add("focused")}
                        onBlur={(e) => e.target.classList.remove("focused")}
                      >
                        <option value="">All</option>
                        <option value="English">English</option>
                        <option value="Marathi">Marathi</option>
                      </select>
                      <ArrowDropDown className="icon" />
                    </div>
                  </div>
                </div>
                <Row className="PastTraining position-relative">
                  {filteredEvents.map((arrList, index) => (
                    <Col
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                    >
                      <Card>
                        <Link>
                          <Card.Body
                            onClick={(e) =>
                              this.handleReadMore(
                                e,
                                arrList.fldi_id,
                                arrList.fldv_title
                                  .replace(/ /g, "-")
                                  .toLowerCase()
                              )
                            }
                          >
                            <div className="position-relative">
                              {/* <div className="SpeakerBox text-center"> */}
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <div className="Speaker">
                                    <figure>
                                      <div className="d-block imgBox">
                                        <ArticleImageLoader
                                          imageUrl={arrList?.fldv_image_url}
                                          altText={"img"}
                                        />
                                      </div>
                                      <p>{arrList.fldv_speaker_name}</p>
                                    </figure>
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  <table className="table">
                                    <tr>
                                      <th colSpan="2" className="topic">
                                        {arrList.fldv_title}
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr>
                                        <th>Date:</th>
                                        <td>
                                          {Moment(
                                            arrList.fldv_select_date
                                          ).format("ddd, D MMM, YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Time:</th>
                                        <td>
                                          {Moment(
                                            arrList.fldt_time
                                          ).format("LT")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Duration:</th>
                                        <td>{arrList.fldv_duration} hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <div> */}
                                  <div className="action">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="readmore"
                                      onClick={(e) =>
                                        this.handleReadMore(
                                          e,
                                          arrList.fldi_id,
                                          arrList.fldv_title
                                            .replace(/ /g, "-")
                                            .toLowerCase()
                                        )
                                      }
                                    >
                                      Read More
                                    </Button>
                                    {arrList.is_bookmark ? (
                                      <IconButton
                                        style={{ color: "orange" }}
                                        className="bookmark"
                                        onClick={(e) =>
                                          this.handleRemoveBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <Bookmark />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        className="bookmark"
                                        onClick={(e) =>
                                          this.handleAddBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <BookmarkBorder />
                                      </IconButton>
                                    )}
                                    {/* </div> */}
                                  </div>
                                </Grid>
                                {/* </div> */}
                              </Grid>
                            </div>
                          </Card.Body>
                        </Link>
                      </Card>

                      {/* <div className="d-flex justify-content-center position-absolute boxFeedback">
                          <Button
                            onClick={(e) =>
                              this.handleReadMore(
                                e,
                                arrList.fldi_id,
                                arrList.fldv_title
                                  .replace(/ /g, "-")
                                  .toLowerCase()
                              )
                            }
                            className="CheckButton text-center"
                          >
                            {" "}
                            Read More
                          </Button>
                          <IconButton>
                            <Bookmark />
                          </IconButton>
                        </div> */}
                    </Col>
                  ))}
                  <div className="clearfix"></div>
                  <Col col="sm-12" className="text-center noFound">
                    {" "}
                    <h3>{this.state.noData}</h3>
                  </Col>
                </Row>
                <div
                  ref={(loadingRef) => (this.loadingRef = loadingRef)}
                  style={loadingCSS}
                >
                  <span style={loadingTextCSS}>
                    <Spinner variant="primary" />
                  </span>
                  <span>{this.state.scrolling ? this.state.noData : ""}</span>
                </div>
              </Container>
            </section>
            <div className="customFooter mt-0">
              <Footer />
            </div>
            {/* <ShareDialog
              open={showShareDialog}
              onClose={this.handleCloseShareDialog}
              shareUrl={clickedCardData ? clickedCardData.fldv_video_url : ""}
              title={clickedCardData ? clickedCardData.fldv_title : ""}
              date={
                clickedCardData
                  ? Moment(clickedCardData.fldv_select_date).format(
                      "dddd, D MMM, YYYY"
                    )
                  : ""
              }
              time={
                clickedCardData
                  ? Moment(clickedCardData.fldv_from_time).format("hh:mmA")
                  : ""
              }
            /> */}
            {/* {filteredEvents.map((arrList) => (
             
            ))} */}
          </div>
        )}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(PastTraining);
