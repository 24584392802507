import React, { useState, useEffect, useRef } from "react";

import "../style/HomeEvents.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Button from "@material-ui/core/Button";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  apiURL,
  isLogin,
  getID,
} from "../utils";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Snackbar,
  IconButton,
  Container,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import Moment from "moment";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RegistrationForm from "../webinar/RegistrationForm";
import { BookmarkBorder, Bookmark } from "@material-ui/icons";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const UpcomingEvHome = (props) => {
  const [upcomeingEventsList, setUpcomeingEventsList] = useState([]);
  const [registerDialog, setRegisterDialog] = useState(false);
  const [userData, setUserData] = useState([]);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severityColor, setSeverityColor] = useState("");
  const [snackBarTimer, setSnackBarTimer] = useState("");
  const [selectedItem, setSelectedItem] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    getUpComeinEvents();
    getUserData();
  }, []);


  useEffect(() => {
    if (registerDialog) {
      getUserData();
    }
  }, [registerDialog]);

  const snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getUserData = () => {
    const body = {
      fldi_id: getID(),
    };
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher/get_profile"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const firstname =
            resultData.result.fldv_first_name !== ""
              ? resultData.result.fldv_first_name
              : resultData.result.fldv_name;
          const lastname =
            resultData.result.fldv_last_name !== ""
              ? resultData.result.fldv_last_name
              : resultData.result.fldv_surname;
          const role = resultData.result.fldv_faculty_status;
          const contact = resultData.result.fldv_contact;
          const email = resultData.result.fldv_email;
          const city = resultData.result.fldi_city;
          const schcollname = resultData.result.fldv_school_college;
          const pincode = resultData.result.fldv_pincode;
          const board = resultData.result.fldv_board_medium;

          const grades = resultData.result.fldv_grades_taught
            ? resultData.result.fldv_grades_taught
                .split(",")
                .map((grade) => grade.trim())
            : [];

          const subjects = resultData.result.fldv_subjects_taught
            ? resultData.result.fldv_subjects_taught
                .split(",")
                .map((sub) => sub.trim())
            : [];
          const userData = {
            firstname,
            lastname,
            role,
            city,
            contact,
            email,
            schcollname,
            pincode,
            board,
            grades,
            subjects,
          };
          setUserData(userData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUpComeinEvents = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "UE",
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/events?per_page=" + 4 + "&page=" + 1), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setUpcomeingEventsList(resultData.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRegistrationSuccess = (isJoined, webinarId) => {
    const updatedEventsList = upcomeingEventsList.map((event) => {
      if (event.fldi_id === webinarId) {
        return { ...event, join_webinar: isJoined };
      }
      return event;
    });

    setUpcomeingEventsList(updatedEventsList);
  };

  const registerForWebinar = (e, webinarId, title, date, time) => {
    e.stopPropagation();
    if (!isLogin()) {
      props.history.push("/sign");
    } else {
      setRegisterDialog(true);
      setId(webinarId);
      setTitle(title);
      setDate(date);
      setTime(time);
    }
  };

  const registerWebinarClose = () => {
    setRegisterDialog(false);
    setId("");
    setTitle("");
    getUserData();
  };

  const handleReadMore = (e, id, title) => {
    e.preventDefault();
    if (!isLogin()) {
      props.history.push("/sign");
    } else {
      props.history.push("/webinar/" + id + "/" + title);
    }
  };

  const handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      props.history.push("/sign");
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_webinar",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = upcomeingEventsList.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...upcomeingEventsList];
              updatedEventsList[index].is_bookmark = true;
              setUpcomeingEventsList(updatedEventsList);
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = upcomeingEventsList.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          setUpcomeingEventsList(updatedEventsList);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrev = () => {
    setSelectedItem((prevItem) => Math.max(prevItem - 1, 0));
  };


  const handleNext = () => {
    const totalPages = Math.ceil(upcomeingEventsList.length / (isMobile ? 1 : 2));
    setSelectedItem((prevItem) => Math.min(prevItem + 1, totalPages - 1));
  };

  const displayData = isMobile
    ? upcomeingEventsList?.slice(selectedItem, selectedItem + 1)
    : upcomeingEventsList?.slice(selectedItem * 2, selectedItem * 2 + 2);
  const singleData = displayData?.length === 1;

  return (
    <React.Fragment>
      {upcomeingEventsList?.length > 0 && (
        <div>
          <section className="mainEveSection">
            <h2 className="text-center">Upcoming Events</h2>
            <Container maxWidth="lg" className="container">
              <Carousel
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => setSelectedItem(index)}
              >
                <Row className="upcomingWebHome position-relative">
                  {displayData.map((arrList, index) => (
                    <Col
                      key={index}
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                    >
                      <Card
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          handleReadMore(
                            e,
                            arrList.fldi_id,
                            arrList.fldv_title.replace(/ /g, "-").toLowerCase()
                          )
                        }
                      >
                        <Card.Body>
                          <div className="position-relative">
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <div className="Speaker">
                                  <Button
                                    className="mode"
                                    style={{
                                      background:
                                        arrList.fldv_mode === "Physical"
                                          ? "#064e89"
                                          : "#a03fa0",
                                    }}
                                  >
                                    {arrList.fldv_mode}
                                  </Button>
                                  <figure>
                                    <div className="d-block imgBox">
                                      <ArticleImageLoader
                                        imageUrl={arrList.fldv_image_url}
                                        altText={"img"}
                                      />
                                      {/* <img
                                        src={arrList.fldv_image_url}
                                        className="img-fluid"
                                        alt="img"
                                      /> */}
                                    </div>
                                    <p>{arrList.fldv_speaker_name}</p>
                                  </figure>
                                </div>
                              </Grid>
                              <Grid item xs={8}>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th colSpan="2" className="topic">
                                        {arrList.fldv_title}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th>Date:</th>
                                      <td>
                                        {Moment(
                                          arrList.fldv_select_date
                                        ).format("ddd, D MMM, YYYY")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Time:</th>
                                      <td>
                                        {Moment(arrList.fldt_time).format("LT")}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th>Duration:</th>
                                      <td>{arrList.fldv_duration} hrs</td>
                                    </tr>
                                    <tr>
                                      <th>Venue:</th>
                                      <td>{arrList.fldv_venue_location}</td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div className="action">
                                  <div className="readmoreContainer">
                                    {arrList.join_webinar ? (
                                      <Button className="registered">
                                        {" "}
                                        Registered{" "}
                                        <span>
                                          <IoMdCheckmarkCircleOutline />
                                        </span>
                                      </Button>
                                    ) : (
                                      <Button
                                        className="readmore"
                                        disabled={Moment(arrList.fldv_select_date).isBefore(Moment(), 'day')}
                                        onClick={(e) =>
                                          registerForWebinar(
                                            e,
                                            arrList.fldi_id,
                                            arrList.fldv_title,
                                            Moment(
                                              arrList.fldv_select_date
                                            ).format("MM DD YYYY"),
                                            Moment(arrList.fldt_time).format(
                                              "LT"
                                            )
                                          )
                                        }
                                      >
                                        {" "}
                                        Register{" "}
                                      </Button>
                                    )}
                                  </div>
                                  <div className="bookmarkContainer">
                                    {arrList.is_bookmark ? (
                                      <IconButton
                                        style={{ color: "orange" }}
                                        className="bookmark"
                                        onClick={(e) =>
                                          handleRemoveBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <Bookmark />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        className="bookmark"
                                        onClick={(e) =>
                                          handleAddBookMark(e, arrList.fldi_id)
                                        }
                                      >
                                        <BookmarkBorder />
                                      </IconButton>
                                    )}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel>
              <IconButton
                onClick={handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>

          <Dialog
            onClose={(e) => registerWebinarClose(e)}
            aria-labelledby="customized-dialog-title"
            open={registerDialog}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={(e) => registerWebinarClose(e)}
            >
              {title}
            </DialogTitle>
            <DialogContent>
              <RegistrationForm
                id={id}
                userData={userData}
                date={date}
                time={time}
                onClose={registerWebinarClose}
                onSnackClose={snackBarClose}
                componentUpdate={getUpComeinEvents}
                onSuccess={(isJoined) =>
                  handleRegistrationSuccess(isJoined, id)
                }
              />
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>
          <Snackbar
            open={showSnackbar === true}
            autoHideDuration={snackBarTimer}
            onClose={snackBarClose}
          >
            <Alert
              onClose={snackBarClose}
              severity={severityColor}
              elevation={6}
              variant="filled"
            >
              {snackBarMessage}
            </Alert>
          </Snackbar>
        </div>
      )}
    </React.Fragment>
  );
};


export default UpcomingEvHome;