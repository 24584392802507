import React, { createContext, useState, useContext, useEffect } from 'react';
import { getUsername } from '.';

// Create the context
const UserContext = createContext();

// Create a provider component
export const UserProvider = ({ children }) => {
  const [userName, setUserName] = useState("");

  
  useEffect(() => {
    setUserName(getUsername())
  }, [userName]); 

  return (
    <UserContext.Provider value={{ userName, setUserName }}>
      {children}
    </UserContext.Provider>
  );
};

// Create a custom hook for easier usage
export const useProfile = () => useContext(UserContext);
